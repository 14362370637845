import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#22798C',
        secondary: '#FF9900',
        accent: '#82B1FF',
        error: '#EB5757',
        info: '#2196F3',
        success: '#27AE60',
        warning: '#FFC107',
        bgGray: '#F2F1F6',
        red: '#EB5757',
      }
    }
  }
});
