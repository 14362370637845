<template>
  
  <v-container>
    <v-row style="background:rgba(242, 241, 246, 0.7)" class="px-2 py-3 rounded-xl">

      <v-col cols="4" v-for="n in nums" :key="n" class="pa-1">
        <v-btn block rounded depressed color="#FFFFFF33"
          @click="numClicked(n)">
          <span class="text-h6">{{ n }}</span>
        </v-btn>
      </v-col>

      <v-col cols="4" class="pa-1">
        <v-btn block rounded depressed color="transparent" @click="backspace">
          <v-icon>mdi-backspace</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="4" class="pa-1">
        <v-btn block rounded depressed color="#FFFFFF33"
          @click="numClicked(0)">
          <span class="text-h6">0</span>
        </v-btn>
      </v-col>

      <v-col cols="4" class="pa-1">
        <v-btn block rounded depressed color="secondary"
          @click="confirm" :disabled="confirmDisabled">
          送出
        </v-btn>
      </v-col>
      

    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    confirmDisabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {

    numClicked(n) {
      this.$emit('enter', n);
    },

    backspace() {
      this.$emit('enter', 'backspace');
    },
    confirm() {
      this.$emit('confirm');
    }
  },
  
  data() {
    return {
      nums: [ 1, 2, 3, 4, 5, 6, 7, 8, 9 ]
    }
  }
}
</script>

<style scoped>
.v-btn {
  height: 48px !important;
}
</style>