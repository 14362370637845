<template>
  <v-container>

    <!-- Footer - Button for applying withdrawal -->
    <v-footer fixed style="background:transparent;">
      <v-btn large block rounded color="primary"
        @click="$router.push('/admin/stores/' + storeId + '/withdraw')">
        <CustomIcon :src="require('@/assets/icons/withdraw.svg')" :width="24"></CustomIcon>
        &nbsp;申請提領
      </v-btn>
    </v-footer>

    <!-- Table of withdrawal records -->
    <SSRTable ref="list-withdrawals" hide-headers
      :data-url="fetchWithdrawalsUrl" :cols="withdrawals.cols">

      <div slot="left" slot-scope="{ item }" class="my-2">
        <span class="text-subtitle-2 font-weight-bold">{{ item.createdTs | tsToDateString }}</span>
        <br>
        {{ item.approvalStatus + '-' + item.grantStatus | toGrantStatusLiteral }}
      </div>

      <!-- <div slot="approvalStatus" slot-scope="{ item }">
        {{ item.approvalStatus | toApprovalStatusLiteral }}
      </div> -->
      
      <div slot="amount" slot-scope="{ item }">
        <div class="d-flex justify-end align-center" v-if="item.approvalStatus != 'insufficient'">
          <CustomIcon :src="require('@/assets/icons/point.svg')" :width="24"></CustomIcon>
          &nbsp;&nbsp;
          <span class="text-subtitle-1 font-weight-bold">{{ item.amount }}</span>
        </div>
        <div class="d-flex align-center" v-else>
          <v-btn small rounded outlined color="primary"
            @click="$router.push('/admin/stores/' + storeId + '/withdraw?reApplication=' + item.id)">
            申請提領補件
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>

    </SSRTable>

    <!-- A dummy spacing at the end of list FIXME: to avoid content overlapping -->
    <div style="height: 72px;">
    </div>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable.vue';
import DateRangePicker from '@/components/DateRangePicker.vue';

import SSORequest from '@/sso-request';
// import axios from 'axios';
import moment from 'moment';

export default {
  components: {
    SSRTable, DateRangePicker
  },
  props: {
    storeId: String
  },
  
  mounted() {
    // Load necessary data.
    if (this.storeId) {
      this.fetchStoreInfo();
      this.fetchWithdrawals();
      console.warn(this.storeRoleId);
    }
  },

  methods: {

    fetchStoreInfo() {
      // Obtain points settings and values.
      this.$store.dispatch('fetchPointServicesByStoreId', this.storeId)
      .then(data => {
        console.log(`[TRANSACTIONS]<DEBUG> fetchStoreInfo: got cached points data`, data);
        this.points = data;

        // Populate periods array.
        this.periods.rows = [ ];
        this.points.forEach(p => {
          if (!p.pointPeriods) return;
          p.pointPeriods.forEach(pp => {
            this.periods.rows.push(pp);
          })
        });
      });
    },

    fetchWithdrawals() {
      setTimeout(() => {
        this.$refs['list-withdrawals'].loadData()
        .then(data => {
          this.withdrawals.data = data;
          // console.log(this.withdrawals.data);
        });
      });
    }

  },

  data() {
    return {
      points: [ ],

      // Data table data

      withdrawals: {
        rows: [ ],
        cols: [
          { text: '', value: 'left' },
          // { text: '審核狀態', value: 'approvalStatus', sortable: false },
          { text: '', value: 'amount', align: 'right' },
        ],
        data: null
      },

      // Query parameters
      // storeId: null,
      dates: [ ],

      // dialog: {
      //   timeRange: {
      //     show: false,
      //     dates: [
      //       moment().startOf('day').toDate(),
      //       moment().endOf('day').toDate()
      //     ]
      //   }
      // }
      
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    fetchWithdrawalsUrl() {
      if (!this.storeId) return null;
      // let fromTs = moment(this.orders.dates[0], 'YYYY-MM-DD').startOf('day').unix();
      // let toTs = moment(this.orders.dates[1], 'YYYY-MM-DD').endOf('day').unix();
      return `${process.env.VUE_APP_TY_POINTS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/stores/${this.storeId}/withdraw?foo=bar`
        // + (this.orders.search? `&search=${this.orders.search}`: '')
        // + `&from_ts=${fromTs}&to_ts=${toTs}`;//WARNING: Hard-coded from-to timestamps
    }

  },

  filters: {
    toDateString(t) {
      return moment(t, 'YYYY-MM-DD').format('YYYY/M/D');
    },
    tsToDateString(ts) {
      return moment.unix(ts).format('YYYY/M/D hh:mmA');
    },
    tsToDateStringMore(ts) {
      return moment.unix(ts).format('YYYY/M/D h:mm:ssA');
    },
    toTypeLiteral(t) {
      return {
        'Budget-Allocation': '預算分配',
        'Consumption-Discount': '消費折抵'
      }[ t ] || '';
    },
    toOrderStatusLiteral(t) {
      return {
        '0': '已完成',
        '1': '已取消',
        'confirmed': '已完成',
        'cancelled': '已取消'
      }[ t ] || '';
    },
    toSettlementStatusLiteral(t) {
      return {
        'open': '未結算',
        'closed': '結算中',
        'settled': '已結算'
      }[ t ] || '';
    },

    // toApprovalStatusLiteral(t) {
    //   return {
    //     'pending': '審核中',
    //     'approved': '已完成',
    //     'rejected': '審核失敗',
    //     'insufficient': '待補件'
    //   }[ t ] || '';
    // },

    toGrantStatusLiteral(t) {
      let [ approvalStatus, grantStatus ] = t.split('-');
      if (approvalStatus != 'approved') {
        return {
          'pending': '審核中',
          'approved': '已完成',
          'rejected': '審核失敗',
          'insufficient': '資料不齊全、待補件'
        }[ approvalStatus ] || '';
      }
      else {
        return {
          'processing': '撥款進行中',
          'granted': '撥款成功'
        }[ grantStatus ] || '';
      }
    }
  }
}
</script>

<style scoped>
div:not(.v-dialog) > .theme--light.v-card {
  background-color: rgba(255, 255, 255, 0.1);
}

.theme--light.v-data-table {
  background-color: transparent;
}

.lightgreen--text {
  color:#00E676;
}
</style>