<template>
  <v-app>
    <v-container>
      <v-row align="center" class="fill-height" @click="showPwaPrompt = false">
        
        <v-col cols="12">
          <v-img :src="require('@/assets/img/logo.svg')" max-width="40%" class="mx-auto">
          </v-img>
          <span class="mt-8 d-block text-subtitle-2 text-center">點點雲</span>
          <!-- <span class="mt-4 d-block text-h6 text-center">特店管理後台</span> -->
          <div class="ml-1 d-flex justify-center" v-if="isProcessing">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>

        </v-col>
        
        <v-col cols="12" class="px-8 d-flex">
          <v-btn block x-large rounded raised color="secondary" @click="enter">
            特店管理後台
          </v-btn>
        </v-col>

      </v-row>

      <!-- PWA Prompt Popup -->
      <div class="pwa-prompt" :class="{'pwa-prompt-non-safari': browserType != 'safari'}"
        v-if="showPwaPrompt">
        <div class="pwa-prompt-body">

          <v-card color="#F2F1F6" width="90%" elevation="0"
            class="mx-auto pb-2 rounded-xl">
            <v-card-text class="d-flex flex-column">
              <CustomIcon :src="require('@/assets/img/logo.svg')" :height="72" class="mx-auto my-4"></CustomIcon>
              <span class="pb-2 text-h6 text-center">安裝點點雲特店管理後台？</span>
              <span class="text-subtitle-2 text-center">安裝點點雲到您的首頁，以享有最完整之功能</span>
              <span class="text-subtitle-2 text-center" v-if="browserType == 'safari'">
                按下Safari分享選單
                <CustomIcon :src="require('@/assets/icons/safari-share.svg')" :height="24" class="mx-1"></CustomIcon>
                ，點選「加入主畫面」
              </span>     
            </v-card-text>
            <v-card-actions class="d-flex" v-if="browserType != 'safari'">
              <v-btn rounded depressed color="white" class="flex-grow-1" style="color:#565656"
                @click="isProcessing = false; showPwaPrompt = false">
                暫時不要
              </v-btn>
              <v-btn rounded depressed color="primary" class="flex-grow-1"
                @click="installPwa">
                加到首頁
              </v-btn>
            </v-card-actions>
          </v-card>

        </div>

        <div class="pwa-prompt-triangle">

        </div>
      </div>

    </v-container>

    

  </v-app>
</template>

<script>
export default {

  mounted() {

    const browserType = this.browserType;

    // For non-Safari browsers, we can prompt for install PWA.
    if (browserType !== 'safari') {
      setTimeout(() => {
        console.log(`[ENTRY] Is PWA prompt ready?`, window.deferredPrompt, browserType);
        // If not ready, no need to prompt for PWA. Just try signin.
        if (!window.deferredPrompt) {
          setTimeout(() => {
            this.attemptSignin();
          }, 1000);
        }
        // Otherwise, show a popup to prompt.
        else {
          this.showPwaPrompt = true;
        }
      }, 500);
    }
    // For Safari, we cannot ask for installing PWA. We can just hint if needed.
    else {
      // We can check the display-mode of the current view.
      // If it's not "browser", it probably means "an install app or bookmark".
      console.log(`[ENTRY] This seems to be a Safari...`, window.deferredPrompt, browserType);
      if (this.pwaDisplayMode != 'browser') {
        setTimeout(() => {
          this.attemptSignin();
        }, 1000);
      }
      // Otherwise, show the instruction to install PWA (add to home screen).
      //WARNING: This however, cannot detect whether the user has already done this before.
      else {
        this.showPwaPrompt = true;
      }

    }
    
  },

  methods: {
    enter() {
      this.$router.push('/admin/stores');
    },

    attemptSignin() {
      window.qcsso
      .init({
        appId: process.env.VUE_APP_QCSSO_APP_ID
      })
      .then(() => {
        if (!window.qcsso.isLoggedIn()) {
          console.warn(`[ENTRY] attemptSignin: QCSSO said you're not logged in, will redirect you to it`);
          window.qcsso.login({ redirectUrl: `${process.env.VUE_APP_WEB_HOST}/admin` });//WARNING: This should redirect you away.
          // resolve();
        }
        else {
          console.log(`[ENTRY] attemptSignin: You have signed in, let's just go to the main page~`);
          this.enter();
        }

      });
    },

    async installPwa() {
      // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
      window.deferredPrompt.prompt();
      // Find out whether the user confirmed the installation or not
      const { outcome } = await window.deferredPrompt.userChoice;
      // The deferredPrompt can only be used once.
      window.deferredPrompt = null;
      // Act on the user's choice
      if (outcome === 'accepted') {
        console.log('[ENTRY] User accepted the install prompt.');
      }
      else if (outcome === 'dismissed') {
        console.log('[ENTRY] User dismissed the install prompt');
        console.log('[ENTRY] Proceed to login then~');
        this.attemptSignin();
      }
    },

  },

  data() {
    return {
      showPwaPrompt: false,
      isProcessing: true
    }
  },
  
  computed: {
    browserType() {
      const ua = navigator.userAgent;
      console.log(`[ENTRY]<DEBUG> computed.browserType`, ua);
      if (ua.match(/opr\//i))
        return 'opera';
      if (ua.match(/chrome|chromium|crios/i))
        return 'chrome';
      if (ua.match(/safari/i))
        return 'safari';
      if (ua.match(/firefox|fxios/i))
        return 'firefox';
      if (ua.match(/edg/i))
        return 'edge';
      return 'No browser detection';
    },

    pwaDisplayMode() {
      const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
      if (document.referrer.startsWith("android-app://")) {
        return "twa";
      } else if (navigator.standalone || isStandalone) {
        return "standalone";
      }
      return "browser";
    }
  }
}
</script>

<style scoped>
.container {
  height: 100vh; 
}

.pwa-prompt {
  position: absolute;
  width: 100%;
  /* height: 360px; */
  background: transparent;
  bottom: 0;
  left: 0;
}

.pwa-prompt.pwa-prompt-non-safari {
  margin-bottom: 56px;
}

.pwa-prompt .pwa-prompt-body {
  position: relative;
  width: 100%;
}

.pwa-prompt .pwa-prompt-body .v-card {
  margin-bottom: 20px;
}

.pwa-prompt .pwa-prompt-body .v-card__text .text-h6 {
  color: #565656;
}
.pwa-prompt .pwa-prompt-body .v-card__text .text-subtitle-2 {
  color: #808080;
}

.pwa-prompt .pwa-prompt-triangle {
  position: absolute;
  bottom: 1px;
  left: 50%;
  margin-left: -15px;
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #F2F1F6;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #22769C;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

</style>