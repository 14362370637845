<template>
  <v-container>

    <validation-observer ref="observer">

      <v-form>
        <v-row>
          <v-col cols="12" class="pb-0">
            <validation-provider v-slot="{ errors }" name="Name" rules="required|min:2">
              <v-text-field dense filled rounded class="align-right-input"
                v-model="form.name" :error-messages="errors">
                <template v-slot:prepend-inner>
                  <span class="text-subtitle-1 font-weight-bold">名稱<sup>*</sup></span>
                </template>
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" class="py-0">
            <validation-provider v-slot="{ errors }" name="Desc" rules="min:2">
              <v-text-field dense filled rounded class="align-right-input"
                v-model="form.desc" :error-messages="errors">
                <template v-slot:prepend-inner>
                  <span class="text-subtitle-1 font-weight-bold">優惠說明</span>
                </template>
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" class="py-0">
            <validation-provider v-slot="{ errors }" name="ConsumptionAmountPerPoint" rules="required|numeric">
              <v-text-field dense filled rounded class="align-right-input " type="number"
                placeholder="輸入金額" suffix="元" v-model.number="form.consumptionAmountPerPoint" :error-messages="errors">
                <template v-slot:prepend-inner>
                  <span class="text-subtitle-1 font-weight-bold text-no-wrap">消費金額發放1點<sup>*</sup></span>
                </template>
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <span class="text-button">注意事項</span>
            <validation-provider v-slot="{ errors }" name="Notices" rules="min:2">
              <v-textarea filled rounded placeholder="注意事項"
                v-model="form.notices" :error-messages="errors">
              </v-textarea>
            </validation-provider>
          </v-col>
          <v-col cols="12" class="pt-0 d-flex justify-space-between align-center">
            <span class="text-button">線上點餐發點<sup>*</sup></span>
            <v-switch class="pt-1" v-model="form.onlineCollection" color="secondary" inset
            ></v-switch>
          </v-col>

          <v-col cols="12" class="d-flex justify-space-between">
            <span class="text-button">集點期間<sup>
              *</sup></span>
            <DateRangePicker v-model="form.collectDateRange">
            </DateRangePicker>
            
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between">
            <span class="text-button">兌換期間<sup>*</sup></span>
            <DateRangePicker v-model="form.redeemDateRange">
            </DateRangePicker>
          </v-col>

          <!-- <v-col cols="12">
            <v-subheader>集點圖示</v-subheader>
            <v-divider></v-divider>  
          </v-col>
          <v-col cols="12">
            <v-file-input dense rounded filled v-model="form.stampImage"
              prepend-icon=""  prepend-inner-icon="mdi-file-upload"
              show-size counter accept="image/png, image/jpeg"
              truncate-length="15">
            </v-file-input>
          </v-col> -->

          <v-col cols="12" class="mt-2">
            <v-btn block large rounded color="secondary"
              :loading="isProcessing" :disabled="isProcessing"
              @click="submit">
              <v-icon left>mdi-content-save</v-icon>
              {{ mode == 'create'? '確定新增': '確定更新' }}
            </v-btn>
          </v-col>

        </v-row>
      </v-form>

    </validation-observer>

  </v-container>
</template>

<script>

import { required, min, max } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

import DateRangePicker from '@/components/DateRangePicker.vue';
import SSORequest from '@/sso-request';
import moment from 'moment';

setInteractionMode('eager');
extend('required', {
  ...required,
  message: `此項目是必填的！`
});
extend('min', {
  ...min,
  message: `長度最少應為{length}字元`
});
extend('max', {
  ...max,
  message: `長度不得超過{length}字元`
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    DateRangePicker
  },
  props: {
    storeId: String,
    stampCardId: {
      type: String,
      default: null
    }
  },
  
  mounted() {
    this.mode = this.stampCardId? 'update': 'create';
    console.log(`[STAMP-CARD-EDIT]<DEBUG> mounted`, this.mode, this.stampCardId);
    if (this.mode == 'update' && this.stampCardId)
      this.fetchStampCardInfo();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {

    fetchStampCardInfo() {
      return SSORequest.get(`${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/stores/${this.storeId}/stamp-cards/${this.stampCardId}`)
      .then(response => {
        const data = response.data;
        /*
        form: {
          name: '我的集點卡',
          desc: '來店消費索取集點回饋',
          notices: `・來店達指定消費內容即可獲得印章。`
            + `\n・如欲兌換，請務必出示給服務人員確認，且經兌換即無法取消。`
            + `\n・本店保有修改內容之權利。`,
          maxStamps: 10,
          collectDateRange: [
            moment().add(1, 'days').startOf('day').format('YYYY-MM-DD'),
            moment().add(1, 'months').startOf('day').format('YYYY-MM-DD'),
          ],
          redeemDateRange: [
            moment().add(1, 'days').startOf('day').format('YYYY-MM-DD'),
            moment().add(1, 'months').startOf('day').format('YYYY-MM-DD'),
          ],
          stampImage: null,
        }
        */

        // Fill up the form for update submission.
        this.form.name = data['stampCardName'];
        // stampCardDesc可能是字串或物件
        if (data['stampCardDesc'] && typeof data['stampCardDesc'] === 'object') {
          this.form.desc = data['stampCardDesc'].find(d => d.title == '優惠說明')['text'];
          this.form.notices = data['stampCardDesc'].find(d => d.title == '注意事項')['text'];
        } else {
          this.form.desc = data['stampCardDesc']
          this.form.notices = null;
        }
        
        // this.form.desc = data['stampCardDesc'].find(d => d.title == '優惠說明')['text'];
        // this.form.notices = data['stampCardDesc'].find(d => d.title == '注意事項')['text'];
        this.form.maxStamps = data['stampCardMaxStamps'];
        this.form.collectDateRange = [
          moment.unix(data['collectStartTime']).format('YYYY-MM-DD'),
          moment.unix(data['collectEndTime']).format('YYYY-MM-DD')
        ];
        this.form.redeemDateRange = [
          moment.unix(data['redeemStartTime']).format('YYYY-MM-DD'),
          moment.unix(data['redeemEndTime']).format('YYYY-MM-DD')
        ];
        this.form.consumptionAmountPerPoint = data['loyaltyProgram']?.purchase?.purchase;
        this.form.onlineCollection = data['apps']?.quickclick;
      })
      .catch(err => {
        this.$notify({
          type: 'error', text: '無法取得集點卡資訊！'
        });
      });
    },

    submit() {
      console.warn(`[STAMP-CARD-EDIT]<DEBUG> submit: will submit (mode ${this.mode})`, this.form);
      this.$refs['observer'].validate()
      .then(result => {
        if (!result) {
          this.$notify({
            type: 'error', text: '請檢查表單是否完整。'
          });
          return;
        }

        this.isProcessing = true;

        // Determine submission API URL.
        let url = `${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${this.user.merchantId}`
          + `/stores/${this.storeId}/stamp-cards`;
        let method = 'post';
        if (this.mode === 'create') {
          url += `/simple-create`;//FIXME: Simpler version of stamp-card creation.
        }
        else {
          url += `/${this.stampCardId}`;
          method = 'put';
        }

        // Obtain timestamp of all date-time parameters.
        let collectStartTime = moment(this.form.collectDateRange[0], 'YYYY-MM-DD').startOf('day');
        let collectEndTime = moment(this.form.collectDateRange[1], 'YYYY-MM-DD').endOf('day');
        let redeemStartTime = moment(this.form.redeemDateRange[0], 'YYYY-MM-DD').startOf('day');
        let redeemEndTime = moment(this.form.redeemDateRange[1], 'YYYY-MM-DD').endOf('day');
        let reqBody = {
          name: this.form.name,
          desc: [
            { title: '優惠說明', text: this.form.desc },
            { title: '注意事項', text: this.form.notices }
          ],
          maxStamps: this.form.maxStamps,
          collectStartTime: collectStartTime.unix(),
          collectEndTime: collectEndTime.unix(),
          redeemStartTime: redeemStartTime.unix(),
          redeemEndTime: redeemEndTime.unix(),
          loyaltyProgram: {
            purchase: {
              purchase: this.form.consumptionAmountPerPoint,
              accrualAmount: 1
            }
          },
          apps: {
            quickclick: this.form.onlineCollection,
          }
        };
        return SSORequest({
          method, url,
          data: reqBody
        });
      })
      .then(response => {
        if (!response) return;
        this.$notify({
          type: 'success',
          text: {
            create: `集點卡：${this.form.name} 建立成功！`,
            update: `集點卡：${this.form.name} 更新成功！`
          }[ this.mode ]
        });

        if (this.mode == 'create')
          this.$router.replace('/admin/stores/' + this.storeId + '/stamp-cards');
        else
          this.$router.replace('info');
      })
      .catch(err => {
        this.$notify({
          type: 'error',
          text: {
            'create': `發生錯誤，無法新增集點卡。`,
            'update': `發生錯誤，無法更新集點卡。`
          }[ this.mode ]
        });
      })
      .finally(() => {
        this.isProcessing = false;
      });
    }
  },

  data() {
    return {
      mode: 'create',
      isProcessing: false,

      form: {
        name: '我的集點卡',
        desc: '來店消費索取集點回饋',
        notices: `・來店達指定消費內容即可獲得點數。`
          + `\n・如欲兌換，請務必出示給服務人員確認，且經兌換即無法取消。`
          + `\n・本店保有修改內容之權利。`,
        maxStamps: 8000000, // 給很大的值讓集點數無上限
        collectDateRange: [
          moment().add(1, 'days').startOf('day').format('YYYY-MM-DD'),
          moment().add(1, 'months').startOf('day').format('YYYY-MM-DD'),
        ],
        redeemDateRange: [
          moment().add(1, 'days').startOf('day').format('YYYY-MM-DD'),
          moment().add(1, 'months').startOf('day').format('YYYY-MM-DD'),
        ],
        stampImage: null,
        consumptionAmountPerPoint: null,
        onlineCollection: true,
      }

      // defaultRewardImage: '@/assets/icons/medal.png'
    };
  }
}
</script>

<style scoped>
.align-right-input >>> input {
  text-align: right;
}
</style>