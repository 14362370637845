<template>
  <v-container>
    <v-select dense filled rounded hide-details clearable class="mb-2"
      prepend-inner-icon="mdi-filter-variant" placeholder="以特店過濾"
      v-model="selectedStoreId"
      :items="storesAsOptions">
    </v-select>

    <!-- NOTE: Use lazy loading for notifications to save performance -->
    <v-lazy v-for="noti in notifications" :key="noti.refId"
      :options="{ threshold: .5 }">

      <v-card shaped elevation="0" class="my-2 d-flex">

        <v-card-text class="pa-3" @click="showMore(noti)">

          <v-row>

            <v-col cols="11" class="pa-0">
              <v-list-item three-line @click="markRead(noti)">
                <v-list-item-content class="pr-0 text-truncate">
                  <!-- <v-badge color="primary" right dot offset-x="12" offset-y="12" :value="noti.unread"> -->
                    <!-- <span class="font-weight-black"></span> -->
                  <v-list-item-title>
                    <span class="font-weight-black">{{ noti.title }}</span>
                  </v-list-item-title>
                    <!-- {{ noti.title }} -->
                  <!-- </v-badge> -->
                  <v-list-item-title>
                    {{ noti.text }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span>{{ noti.ts | toDateString }}&nbsp;&nbsp;</span>
                    <span class="font-weight-bold text-truncate">{{ noti.storeName }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="1" class="pa-0 d-flex flex-column align-end">
              <v-badge color="primary" right dot offset-x="24" offset-y="24"
                :value="noti.unread">
                <span class="font-weight-black">&nbsp;</span>
              </v-badge>

              <!-- <v-icon class="mt-auto mr-2 mb-2"
                @click="showMore(noti)">
                mdi-dots-vertical
              </v-icon> -->
            </v-col>

          </v-row>

        </v-card-text>

      </v-card>

    </v-lazy>

    <!-- A dummy spacing at the end of list FIXME: to avoid content overlapping -->
    <div style="height: 72px;">
    </div>
    
  </v-container>
</template>

<script>
import EventBus from '@/event-bus';

import _ from 'underscore';
import moment from 'moment';

export default {
  
  mounted() {
    // this.$notify({
    //   group: 'noti-popup',
    //   title: '您收到了 8 點紅利桃子',
    //   text: '太爽啦！'
    // });
    EventBus.$on('app-bar-action-click', this.markAllRead);
  },

  beforeRouteLeave(to, from, next) {
    EventBus.$off('app-bar-action-click');
    next();
  },

  methods: {
    
    markRead(noti) {
      // console.log(`[NOTIFICATIONS]<DEBUG> markRead`, noti);
      if (!noti.unread)
        return;
      this.$store.dispatch('markRead', { topicId: `store-${noti.storeId}`, refId: noti.refId });
    },

    markAllRead() {
      this.$store.dispatch('markAllRead');
    },

    showMore(notification) {
      //NOTE: Temporarily comment-out this function.
      // this.$notify({
      //   group: 'noti-popup',
      //   title: notification.title,
      //   text: `${notification.text}<br>
      //   特店：${notification.storeName}<br>
      //   <span style="color:grey">${moment.unix(notification.ts).format('YYYY/M/D h:mm:ssA')}</span>
      //   `
      // });
      // if (notification.unread == true)
      //   this.markRead(notification);
    }
  },

  data() {
    return {
      topic: { },
      selectedStoreId: null,
      messages: [ ]
    }
  },

  computed: {
    stores() {
      return this.$store.getters.stores;
    },

    storesAsOptions() {
      let stores = this.$store.getters.stores;
      return stores.map(store => {
        return {
          text: store['name'],
          value: `${store['id']}` 
        };
      });
    },

    notifications() {
      let notifications = this.$store.getters.notifications;
      return Object.values(notifications)
      .filter(noti => {
        return this.selectedStoreId == null || this.selectedStoreId == noti.storeId;
      })
      .sort((a, b) => {
        return b.ts - a.ts;
      });
    }

    // messages() {
    //   console.log(`computed messages triggered`, JSON.stringify(this.topic));
    //   let ret = [ ];
    //   for (const topicId in this.topic) {
    //     let temp = this.topic[ topicId ];
    //     console.log(temp);
    //     ret = ret.concat(temp.messages);
    //   }
    //   return ret;
    // }
  },

  filters: {
    toDateString(ts) {
      return moment.unix(ts).format('YYYY/M/D h:mm:ssA');
    }
  }
}
</script>

<style scoped>
.theme--light.v-card {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>