<template>
  <v-container>

    <!-- HEADER for stamp card info. -->
    <v-row>

      <v-col cols="8">
        <v-list-item three-line>
          <v-list-item-content>
            <!-- <div class="text-overline mb-4">
              OVERLINE
            </div> -->
            <v-list-item-title class="text-h6 mb-2">
              {{ stampCard.stampCardName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              集點 {{ stampCard.collectStartTime | toShortDateString }} - {{ stampCard.collectEndTime | toShortDateString }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              兌換 {{ stampCard.redeemStartTime | toShortDateString }} - {{ stampCard.redeemEndTime | toShortDateString }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="4" class="my-2 d-flex justify-center align-center">
        <v-img max-width="84" max-height="84" class="rounded-lg"
          :src="stampCard.stampImageUrl">
        </v-img>
      </v-col>

    </v-row>

    <!-- Row for showing time range and range selector -->
    <v-card flat outlined rounded="xl" class="my-2 pa-2" @click="onTimeRangeClick">
      <div class="px-2 d-flex justify-space-between align-center">
        <div class="text-body-1">
          {{ dates[0] | toDateString }} - {{ dates[1] | toDateString }}
        </div>
        <!-- <div v-if="tab==='stamps'" class="text-body-1">
          {{ stamps.dates[0] | toDateString }} - {{ stamps.dates[1] | toDateString }}
        </div>
        <div v-else-if="tab==='rewards'" class="text-body-1">
          {{ rewards.dates[0] | toDateString }} - {{ rewards.dates[1] | toDateString }}
        </div> -->
      </div>
    </v-card>

    <!-- TAB of Transaction Types -->
    <v-btn-toggle rounded color="primary" class="mb-2 d-flex flex-row" v-model="tab" mandatory>
      <v-btn x-large class="flex-grow-1" value="stamps">
        <div class="d-flex align-center">
          <span class="text-subtitle-1 font-weight-black">發點記錄</span>
        </div>
      </v-btn>
      <v-btn x-large class="flex-grow-1" value="rewards">
        <div class="d-flex align-center">
          <span class="text-subtitle-1 font-weight-black">兌換記錄</span>
        </div>
      </v-btn>
    </v-btn-toggle>

    <!-- Dialog - Order Query Time Range picker -->
    <v-dialog v-model="dialog.timeRange.show">
      <v-card>
        <v-card-title>搜尋交易時間區間</v-card-title>
        <v-card-text>
          <DateRangePicker v-model="dates" :shortcuts="dateRangePickerShortcuts"></DateRangePicker>
          <!-- <DateRangePicker v-if="tab === 'stamps'" v-model="stamps.dates" :shortcuts="dateRangePickerShortcuts"></DateRangePicker>
          <DateRangePicker v-else-if="tab === 'rewards'" v-model="rewards.dates" :shortcuts="dateRangePickerShortcuts"></DateRangePicker> -->
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="fetchTransactions();dialog.timeRange.show = false">
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Table of collect-stamps transactions -->
    <template v-if="tab == 'stamps'">
      <SSRTable ref="list-collect-stamps" :hideHeaders="true"
        :data-url="fetchTransactionsCollectStampsUrl" :data-key="'transactions'" :cols="stamps.cols"
        @click-row="onCollectStampsRowClicked">

        <div slot="left" slot-scope="{ item }">
          <span class="text-subtitle-2">{{item.phone ? '線上訂單發點': '現場發點' }}</span><br>
          <span class="grey--text">{{ item.phone }}</span>
        </div>

        <div slot="right" slot-scope="{ item }">

          <div class="d-flex flex-column">
            <div class="d-flex justify-end align-center">
              <!-- <CustomIcon :src="stampCard.stampImageUrl" :width="24" :height="24" class="rounded"></CustomIcon> -->
              <span class="ml-2 text-h6" style="color:#96BE0B">+{{ item.amount }}</span>
            </div>
            <span>{{ item.createdAt | tsToDateString }}</span>
          </div>
        </div>
      </SSRTable>
    </template>

    <!-- Table of collect-stamps transactions -->
    <div v-if="tab == 'rewards'">
      <SSRTable ref="list-redeem-rewards" :hideHeaders="true"
        :data-url="fetchTransactionsRedeemRewardsUrl" :data-key="'transactions'" :cols="rewards.cols"
        @click-row="onCollectStampsRowClicked">

        <div slot="left" slot-scope="{ item }">
          <span class="text-subtitle-2">獎品兌換 - {{ item.rewardName }}</span><br>
          <span class="grey--text">{{ item.phone }}</span>
        </div>

        <div slot="right" slot-scope="{ item }">
          <div class="d-flex flex-column">
            <!-- <div class="d-flex justify-end align-center">
              <CustomIcon :src="item.rewardImageUrl" :width="24" :height="24" class="rounded"></CustomIcon>
              <span class="ml-2 text-h6" style="color:#27AE60">+{{ item.amount }}</span>
            </div> -->
            <span>{{ item.createdAt | tsToDateString }}</span>
          </div>
        </div>
      </SSRTable>
    </div>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable.vue';
import DateRangePicker from '@/components/DateRangePicker.vue';

import SSORequest from '@/sso-request';
// import axios from 'axios';
import moment from 'moment';

export default {
  components: {
    SSRTable, DateRangePicker
  },
  props: {
    storeId: String,
    stampCardId: String
  },

  mounted() {
    this.fetchStampCardInfo();
  },

  methods: {

    fetchStampCardInfo() {
      return SSORequest.get(`${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/stores/${this.storeId}/stamp-cards/${this.stampCardId}`)
      .then(response => {
        this.stampCard = response.data;
      })
      .catch(err => {
        this.$notify({
          type: 'error', text: '無法取得集點卡資訊！'
        });
      });
    },

    onCollectStampsRowClicked(transaction) {

    },

    onTimeRangeClick() {
      this.dialog.timeRange.show = true;
    },  

    fetchTransactions() {
      setTimeout(() => {
        if (this.tab === 'stamps') this.$refs['list-collect-stamps'].loadData();
        else if (this.tab === 'rewards') this.$refs['list-redeem-rewards'].loadData()
        .then(data => {
          if (this.tab === 'stamps') {
            // this.stamps.refreshLoading = false;
            this.stamps.data = data;
          } else if (this.tab === 'rewards') {
            // this.rewards.refreshLoading = false;
            this.rewards.data = data;
          }
        });
      });
    },
  },

  data() {
    return {
      tab: 'stamps',

      stampCard: { },

      stamps: {
        cols: [
          { text: '', value: 'left' },
          { text: '', value: 'right', align: 'end' },
        ], 
        // dates: [
        //   moment().add(-7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')
        // ],
        // refreshLoading: false
      },

      rewards: {
        cols: [
          { text: '', value: 'left' },
          { text: '', value: 'right' },
        ],
        // dates: [
        //   moment().add(-7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')
        // ],
        // refreshLoading: false
      },

      // Query parameters
      dates: [
        moment().add(-7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')
      ],
      dateRangePickerShortcuts: [
        { text: '今天', dates: [ moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD') ] },
        { text: '昨天', dates: [ moment().add(-1, 'days').format('YYYY-MM-DD'), moment().add(-1, 'days').format('YYYY-MM-DD') ] },
        { text: '本週', dates: [ moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD') ] },
        {
          text: '上週', dates: [
            moment().add(-1, 'weeks').startOf('week').format('YYYY-MM-DD'),
            moment().add(-1, 'weeks').endOf('week').format('YYYY-MM-DD')
          ]
        },
      ],

      dialog: {
        search: {
          show: false
        },
        timeRange: {
          show: false,
          dates: [
            moment().startOf('day').toDate(),
            moment().endOf('day').toDate()
          ]
        }
      }
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    fetchTransactionsCollectStampsUrl() {
      if (!this.storeId) return null;
      let from = moment(this.dates[0]).format('YYYYMMDD');
      let to = moment(this.dates[1]).format('YYYYMMDD');
      // let fromTs = moment(this.dates[0], 'YYYY-MM-DD').startOf('day').unix();
      // let toTs = moment(this.dates[1], 'YYYY-MM-DD').endOf('day').unix();
      return `${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/stores/${this.storeId}/transactions?foo=bar&transactionTypes=collect-stamps`
        + `&stampCardId=${this.stampCardId}`
        // + `&from=20220101&to=20301231`;
        + `&from=${from}&to=${to}`;//WARNING: Hard-coded from-to timestamps
    },

    fetchTransactionsRedeemRewardsUrl() {
      if (!this.storeId) return null;
      let from = moment(this.dates[0]).format('YYYYMMDD');
      let to = moment(this.dates[1]).format('YYYYMMDD');
      // let fromTs = moment(this.dates[0], 'YYYY-MM-DD').startOf('day').unix();
      // let toTs = moment(this.dates[1], 'YYYY-MM-DD').endOf('day').unix();
      return `${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/stores/${this.storeId}/transactions?foo=bar&transactionTypes=redeem-reward`
        + `&stampCardId=${this.stampCardId}`
        // + `&from=20220101&to=20301231`;
        + `&from=${from}&to=${to}`;//WARNING: Hard-coded from-to timestamps
    },
  },

  watch: {
    tab(v) {  console.log('tab changed', v); }
  },

  filters: {
    tsToDateString(ts) {
      return moment.unix(ts).format('YYYY/M/D hh:mmA');
    },
    toShortDateString(ts) {
      return moment.unix(ts).format('YYYY/M/D');
    },
    toDateString(t) {
      return moment(t, 'YYYY-MM-DD').format('YYYY/M/D');
    },
  }
}
</script>