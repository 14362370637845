var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('validation-observer',{ref:"observer"},[_c('v-form',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"align-right-input",attrs:{"dense":"","filled":"","rounded":"","error-messages":errors},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v("名稱"),_c('sup',[_vm._v("*")])])]},proxy:true}],null,true),model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Desc","rules":"min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"align-right-input",attrs:{"dense":"","filled":"","rounded":"","error-messages":errors},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v("優惠說明")])]},proxy:true}],null,true),model:{value:(_vm.form.desc),callback:function ($$v) {_vm.$set(_vm.form, "desc", $$v)},expression:"form.desc"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"ConsumptionAmountPerPoint","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"align-right-input ",attrs:{"dense":"","filled":"","rounded":"","type":"number","placeholder":"輸入金額","suffix":"元","error-messages":errors},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"text-subtitle-1 font-weight-bold text-no-wrap"},[_vm._v("消費金額發放1點"),_c('sup',[_vm._v("*")])])]},proxy:true}],null,true),model:{value:(_vm.form.consumptionAmountPerPoint),callback:function ($$v) {_vm.$set(_vm.form, "consumptionAmountPerPoint", _vm._n($$v))},expression:"form.consumptionAmountPerPoint"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("注意事項")]),_c('validation-provider',{attrs:{"name":"Notices","rules":"min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"filled":"","rounded":"","placeholder":"注意事項","error-messages":errors},model:{value:(_vm.form.notices),callback:function ($$v) {_vm.$set(_vm.form, "notices", $$v)},expression:"form.notices"}})]}}])})],1),_c('v-col',{staticClass:"pt-0 d-flex justify-space-between align-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("線上點餐發點"),_c('sup',[_vm._v("*")])]),_c('v-switch',{staticClass:"pt-1",attrs:{"color":"secondary","inset":""},model:{value:(_vm.form.onlineCollection),callback:function ($$v) {_vm.$set(_vm.form, "onlineCollection", $$v)},expression:"form.onlineCollection"}})],1),_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("集點期間"),_c('sup',[_vm._v(" *")])]),_c('DateRangePicker',{model:{value:(_vm.form.collectDateRange),callback:function ($$v) {_vm.$set(_vm.form, "collectDateRange", $$v)},expression:"form.collectDateRange"}})],1),_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-button"},[_vm._v("兌換期間"),_c('sup',[_vm._v("*")])]),_c('DateRangePicker',{model:{value:(_vm.form.redeemDateRange),callback:function ($$v) {_vm.$set(_vm.form, "redeemDateRange", $$v)},expression:"form.redeemDateRange"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","large":"","rounded":"","color":"secondary","loading":_vm.isProcessing,"disabled":_vm.isProcessing},on:{"click":_vm.submit}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.mode == 'create'? '確定新增': '確定更新')+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }