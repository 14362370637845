<template>
  <v-container>
    
    <!-- Banner and Store logo -->
    <div class="mb-12">
      <div class="img-banner">
        <v-img class="rounded-xl" max-height="240px"
          :src="require('@/assets/img/default-store-banner.jpg')" >
        </v-img>
        <v-img class="img-logo"
          :src="require('@/assets/img/default-store-logo.png')" max-width="120px">
        </v-img>
      </div>
    </div>

    <!-- Store Logo -->
    <!-- <div class="d-flex flex-column mb-4">
      <v-img :src="require('@/assets/img/placeholder-store.png')" max-width="30%" class="mx-auto my-5"></v-img>
      <span class="mx-auto text-h5">{{ store.name }}</span>
    </div> -->

    <!-- Store Points Info -->
    <!-- <v-card outlined rounded="xl" class="mx-auto px-4 py-2 mb-4"
      v-for="point in points" :key="point.pointCode">
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="text-h6 mb-1">{{ point.pointName }}</v-list-item-title>
          <v-list-item-subtitle>&nbsp;</v-list-item-subtitle>
          <v-list-item-subtitle class="d-flex align-center">
            <v-chip outlined color="#EF6079" class="ma-2 white--text text-h6">
              {{ point.pointBalance }}
            </v-chip>
            <span class="text-subtitle-2">可提領 {{ closingBalance }} 點</span>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar tile size="80">
          <CustomIcon :src="require('@/assets/icons/point.svg')"></CustomIcon>
        </v-list-item-avatar>

      </v-list-item>
    </v-card> -->

    <!-- Store Title -->
    <div class="mt-6 mb-12 d-flex justify-center align-center">
      <span class="text-h5">{{ store.name }}</span>
      &nbsp;&nbsp;
      <v-btn icon text @click="dialog.qrcode.show = true">
        <!-- <v-icon>mdi-qrcode</v-icon> -->
        <CustomIcon :src="require('@/assets/icons/qrcode-w.svg')" :width="24"></CustomIcon>
      </v-btn>
    </div>

    <!-- Action Buttons -->
    <v-row justify="center">

      <v-col cols="4" class="d-flex justify-center">
        <v-btn text class="store-action-button"
          @click="$router.push(`/admin/stores/${storeId}/transactions`)">
          <div class="d-flex flex-column">
            <CustomIcon :src="require('@/assets/icons/point-w.svg')" :height="48" class="mx-auto"></CustomIcon>
            <span class="mt-2 text-subtitle-1">交易記錄</span>
          </div>
        </v-btn>
      </v-col>

      <v-col cols="4" class="d-flex justify-center">
        <v-btn text class="store-action-button"
          @click="$router.push(`/admin/stores/${storeId}/withdrawals`)">
          <div class="d-flex flex-column">
            <CustomIcon :src="require('@/assets/icons/withdraw-w.svg')" :height="48" class="mx-auto"></CustomIcon>
            <span class="mt-2 text-subtitle-1">提領</span>
          </div>
        </v-btn>
      </v-col>

      <v-col cols="4" class="d-flex justify-center">
        <v-btn text class="store-action-button"
          @click="$router.push('/admin/stores/' + storeId + '/managers')">
          <div class="d-flex flex-column">
            <CustomIcon :src="require('@/assets/icons/auth-w.svg')" :height="48" class="mx-auto"></CustomIcon>
            <span class="mt-2 text-subtitle-1">權限管理</span>
          </div>
        </v-btn>
      </v-col>

      <v-col cols="4" class="d-flex justify-center">
        <v-btn text class="store-action-button"
          @click="$router.push('/admin/stores/' + storeId + '/stamp-cards')">
          <div class="d-flex flex-column">
            <CustomIcon :src="require('@/assets/icons/stamp-w.svg')" :height="48" class="mx-auto"></CustomIcon>
            <span class="mt-2 text-subtitle-1">集章卡</span>
          </div>
        </v-btn>
      </v-col>

      <v-col cols="4" class="d-flex justify-center">
        <v-btn text class="store-action-button"
          @click="$router.push('/admin/stores/' + storeId + '/tickets')">
          <div class="d-flex flex-column">
            <CustomIcon :src="require('@/assets/icons/coupon-w.svg')" :height="48" class="mx-auto"></CustomIcon>
            <span class="mt-2 text-subtitle-1">票券</span>
          </div>
        </v-btn>
      </v-col>

    </v-row>


    <!-- Footer - Management Buttons -->
    <!-- <v-bottom-navigation fixed grow
      height="120px" color="white" background-color="transparent">
      <v-btn color="transparent" @click="$router.push('/admin/withdraw?storeId=' + storeId)">
        <span class="mt-2 text-subtitle-1">申請提領</span>
        <CustomIcon :src="require('@/assets/icons/withdraw.svg')"></CustomIcon>
      </v-btn>

      <v-btn color="transparent"
        @click="dialog.qrcode.show = true">
        <span class="mt-2 text-subtitle-1">QR Code</span>
        <CustomIcon :src="require('@/assets/icons/qrcode.svg')"></CustomIcon>
      </v-btn>

      <v-btn color="transparent" @click="$router.push('/admin/stores/' + storeId + '/managers')">
        <span class="mt-2 text-subtitle-1">權限管理</span>
        <CustomIcon :src="require('@/assets/icons/auth.svg')"></CustomIcon>
      </v-btn>
    </v-bottom-navigation> -->

    <!-- Dialog - Store QR Code -->
    <v-dialog v-model="dialog.qrcode.show">
      <v-card>
        <v-card-title class="d-flex justify-center">{{ store.code }}</v-card-title>
        <v-card-text class="d-flex flex-column">
          <v-progress-circular indeterminate color="white" class="mx-auto"
            v-if="dialog.qrcode.loading">
          </v-progress-circular>
          <v-img :src="'https://chart.apis.google.com/chart?cht=qr&choe=UTF-8&chs=300x300&chl=' + storeQrCodeData"
            class="mx-auto"
            @loadstart="dialog.qrcode.loading = true" @load="dialog.qrcode.loading = false">
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import EventBus from '@/event-bus';

// import axios from 'axios';
import SSORequest from '@/sso-request';

export default {
  props: {
    storeId: String
  },

  mounted() {
    // console.log(`[STORE-INFO] this.$route`, this.$route);
    EventBus.$on('app-bar-action-click', this.goToShopEdit);

    // Obtain current store's data.
    // this.store = this.$store.getters.stores.find(s => s.id == this.storeId);

    // Fetch data
    this.fetchStorePointsInfo();
    this.fetchWithdrawalInfo();
  },

  beforeRouteLeave(to, from, next) {
    // console.log(`[STORE-INFO] beforeRouteLeave`);
    EventBus.$off('app-bar-action-click');
    next();
  },

  methods: {

    fetchStorePointsInfo() {
      SSORequest.get(`${process.env.VUE_APP_TY_POINTS_HOST}/v1/merchants/${this.user.merchantId}/stores/${this.storeId}/points`)
      .then(response => {
        this.points = response.data;
      })
      .catch(err => {
        console.error(`[STORE INFO] fetchStorePointsInfo error`, err);
      });
    },

    fetchWithdrawalInfo() {

      SSORequest.get(`${process.env.VUE_APP_TY_POINTS_HOST}/v1/merchants/${this.user.merchantId}/stores/${this.storeId}/closing-balance`)
      .then(response => {
        this.closingBalance = response.data.closingBalance;
      })
      .catch(err => {
        this.$notify({
          type: 'error',
          text: '無法取得可提領點數資訊！'
        });
      });
    },

    goToShopEdit() {
      this.$router.push(`/admin/stores/${this.storeId}/edit`);
    }
  },

  data() {
    return {
      // store: {},

      points: { },
      closingBalance: 0,

      dialog: {
        qrcode: {
          show: false,
          loading: true
        }
      }
    };
  },

  computed: {
    store() {
      return this.$store.getters.store(this.storeId) || { };
    },

    storeQrCodeData() {
      let store = this.store;
      return encodeURIComponent(`S:${store['code']}`);
    },
    
    user() {
      return this.$store.getters.user;
    }
  },

  watch: {

  }
}
</script>

<style scoped>
.theme--light.v-card {
  background-color: rgba(255, 255, 255, 0.1);
}

.v-dialog__content .v-card {
  background-color: #424242;
}

.v-btn.store-action-button {
  width: 96px;
  height: 96px !important;
}
 
/* Bottom nav (footer) styles */
/* NOTE: A workaround that fixes the issue where buttons don't vertically centered. */
/* .v-item-group.v-bottom-navigation .v-btn.v-size--default {
  height: inherit;
}
.v-btn--active:hover::before {
  opacity: 0 !important;
} */

.img-banner {
  position: relative;
}
.img-logo {
  position: absolute;
  top: 160px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  border: 4px solid white;
  border-radius: 16px;
  background-color: white;
}
</style>