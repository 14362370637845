<template>
  <v-container>

    <!-- Tabbed Header -->
    <v-btn-toggle rounded color="primary" class="mb-2 d-flex flex-row" v-model="tab" mandatory>
      <v-btn x-large class="flex-grow-1" value="products">
        <div class="d-flex align-center">
          <span class="text-subtitle-1 font-weight-black">票券列表</span>
        </div>
      </v-btn>
      <v-btn x-large class="flex-grow-1" value="transactions">
        <div class="d-flex align-center">
          <span class="text-subtitle-1 font-weight-black">核銷記錄</span>
        </div>
      </v-btn>
    </v-btn-toggle>

    <!-- Tab: Ticket Products -->
    <template v-if="tab == 'products'">
      <v-card outlined rounded="xl" class="mx-auto mb-3"
        v-for="product in products" :key="product.productId">

        <v-img :src="product.images[0]" height="120" v-if="product.images && product.images.length">
        </v-img>

        <v-row align="center" class="pt-2">

          <!-- General info -->
          <v-col cols="12">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-body-1 mb-2">
                  {{ product.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ product.description }}
                </v-list-item-subtitle>
                <v-list-item-subtitle :class="{ 'red--text': product.useExpirationTs < nowTs }">
                  使用期限 {{ product.useStartTs | toShortDateString }} - {{ product.useExpirationTs | toShortDateString }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <!-- Action buttons -->
          <v-col cols="12" class="pt-0 d-flex">
            <v-btn text class="flex-grow-1"
              @click="switchToTransactions(product.productId)">
              核銷記錄
            </v-btn>
          </v-col>
        </v-row>

      </v-card>
    </template>
    
    <!-- Tab: Ticket Transactions -->
    <template v-if="tab == 'transactions'">

      <!-- Conditional product info pane -->
      <v-card outlined rounded="xl" class="mx-auto mb-3" v-if="transactions.product">
        <v-img :src="transactions.product.images[0]" height="120"
          v-if="transactions.product.images && transactions.product.images.length">
        </v-img>

        <v-row align="center" class="pt-2">

          <!-- General info -->
          <v-col cols="12">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-body-1 mb-2">
                  {{ transactions.product.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ transactions.product.description }}
                </v-list-item-subtitle>
                <v-list-item-subtitle :class="{ 'red--text': transactions.product.useExpirationTs < nowTs }">
                  使用期限 {{ transactions.product.useStartTs | toShortDateString }} - {{ transactions.product.useExpirationTs | toShortDateString }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card>

      <!-- Query Options -->
      <div class="my-2 d-flex justify-space-between align-center">
        <div class="flex-shrink-1">
          <v-btn large text class="text-subtitle-1" @click="onSearchOrdersClick">
            <v-icon left>mdi-magnify</v-icon>
            <div>
              <span v-if="transactions.search.length">{{ transactions.search }}</span>
              <span v-else>搜尋&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </div>
          </v-btn>
          <v-btn icon class="mr-1" v-show="transactions.search.length"
            @click="clearSearchOrders">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="flex-shrink-1">
          <v-btn rounded depressed color="secondary" class="text-subtitle-1" @click="dialog.timeRange.show = true">
            {{ transactions.dates[0] | toDateString }} - {{ transactions.dates[1] | toDateString }}
          </v-btn>
        </div>
      </div>

      <!-- List of ticket transactions -->
      <SSRTable ref="list-ticket-transactions" :hideHeaders="true"
        :data-url="fetchTicketTransactionUrl" :data-key="'transactions'" :cols="transactions.cols"
        :default-per-page="5"
        @click-row="onTransactionClicked">

        <div slot="left" slot-scope="{ item }">
          <span class="text-subtitle-2">{{ item.name }}</span><br>
          <span class="grey--text">{{ item.memberName }} - {{ item.memberPhone }}</span>
        </div>

        <div slot="right" slot-scope="{ item }">
          <span class="text-subtitle-2">{{ item.transactionStatus | toTransactionStatusLiteral}}</span><br>
          <span class="grey--text">{{ item.transactionTs | toDateTimeString }}</span>
        </div>

      </SSRTable>

    </template>

    <!-- Dialog - Order Search -->
    <v-dialog v-model="dialog.search.show">
      <v-card>
        <v-card-title>搜尋電話號碼</v-card-title>
        <v-card-text>
          <v-text-field dense filled rounded hide-details type="number"
            prepend-inner-icon="mdi-magnify" clearable
            v-model="transactions.search">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary"
            @click="fetchTicketHistory(); dialog.search.show = false;">
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog - Order Query Time Range picker -->
    <v-dialog v-model="dialog.timeRange.show">
      <v-card>
        <v-card-title>查詢時間區間</v-card-title>
        <v-card-text>
          <DateRangePicker v-model="transactions.dates" :shortcuts="dialog.timeRange.shortcuts">
          </DateRangePicker>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="fetchTicketHistory(); dialog.timeRange.show = false">
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable.vue';
import DateRangePicker from '@/components/DateRangePicker.vue';

import SSORequest from '@/sso-request';
import moment from 'moment';

export default {
  components: {
    SSRTable,
    DateRangePicker
  },
  props: {
    storeId: String
  },

  mounted() {
    this.fetchTicketProducts();
  },

  methods: {
      
    fetchTicketProducts() {
      console.log(this.storeId);
      return SSORequest.get(`${process.env.VUE_APP_TY_TICKETS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/stores/${this.storeId}/products`)
      .then(response => {
        this.products = response.data;
      })
      .catch(err => {
        this.$notify({
          type: 'error', text: '無法取得票券列表！'
        });
      });
    },
    fetchProductInfo(_productId) {
      SSORequest.get(`${process.env.VUE_APP_TY_TICKETS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/stores/${this.storeId}/products/${_productId}`)
      .then(response => {
        this.transactions.product = response.data;
      })
      .catch(err => {
        this.$notify({
          type: 'error',
          text: '無法取得票券產品資訊。'
        })
      });
    },
    fetchTicketHistory() {
      setTimeout(() => {
        this.$refs['list-ticket-transactions'].loadData()
        .then(data => {
          
        });
      });
    },

    switchToTransactions(_productId) {
      this.tab = 'transactions';
      if (_productId) {
        this.transactions.selectedProductId = _productId;
        this.fetchProductInfo(_productId);
        this.fetchTicketHistory()
      }
    },

    /// Transactions actions

    onTransactionClicked(order) {
      console.log(`[TICKET-HISTORY] onTransactionClicked`, order);
      // this.dialog.order.data = order;
      // this.dialog.order.show = true;
    },

    onSearchOrdersClick() {
      this.transactions.search = '';
      this.dialog.search.show = true;
    },
    clearSearchOrders() {
      this.transactions.search = '';
      this.fetchTicketHistory();
    },

  },

  data() {
    return {
      tab: 'products',

      products: [ ],

      transactions: {
        selectedProductId: null,
        product: null,

        cols: [
          { text: '交易', value: 'left' },
          { text: '時間', value: 'right', align: 'right' }
        ],

        // Query parameters
        dates: [
          // moment().startOf('day').toDate(),
          // moment().endOf('day').toDate()
          moment().add(-7, 'days').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ],
        search: '',
      },

      // Dialogs
      dialog: {
        transaction: {
          show: false,
        },
        search: {
          show: false
        },
        timeRange: {
          show: false,
          shortcuts: [
            { text: '今天', dates: [ moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD') ] },
            { text: '昨天', dates: [ moment().add(-1, 'days').format('YYYY-MM-DD'), moment().add(-1, 'days').format('YYYY-MM-DD') ] },
            { text: '本週', dates: [ moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD') ] },
            {
              text: '上週', dates: [
                moment().add(-1, 'weeks').startOf('week').format('YYYY-MM-DD'),
                moment().add(-1, 'weeks').endOf('week').format('YYYY-MM-DD')
              ]
            },
          ]
        }
      }
    }
  },
  
  computed: {
    user() {
      return this.$store.getters.user;
    },
    nowTs() {
      return moment().unix();
    },

    fetchTicketTransactionUrl() {
      if (!this.storeId) return null;
      let fromTs = moment(this.transactions.dates[0], 'YYYY-MM-DD').startOf('day').unix();
      let toTs = moment(this.transactions.dates[1], 'YYYY-MM-DD').endOf('day').unix();
      return `${process.env.VUE_APP_TY_TICKETS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/stores/${this.storeId}/transactions?foo=bar`
        + (this.transactions.selectedProductId? `&productId=${this.transactions.selectedProductId}`: '')
        + `&from=${fromTs}&to=${toTs}`
        + ((this.transactions.search && this.transactions.search.length)? `&search=${this.transactions.search}`: '');
    }
  },

  watch: {
    tab(v) {
      // Clear the selected product ID when switching to products tab.
      // To reset transaction tab's query scope.
      console.log(`[TICKETS]<DEUBG> watch.tab`, v);
      if (v == 'products') {
        this.transactions.product = null;
        this.transactions.selectedProductId = null;
      }
    }
  },

  filters: {
    toDateString(t) {
      return moment(t, 'YYYY-MM-DD').format('YYYY/M/D');
    },
    toDateTimeString(ts) {
      if (!ts)
        return '';
      return moment.unix(ts).format('YYYY/M/D h:mmA');
    },
    toShortDateString(ts) {
      if (!ts)
        return '';
      return moment.unix(ts).format('YYYY/M/D');
    },

    toTransactionStatusLiteral(s) {
      return {
        'redeemed': '已核銷',
        'redeem-error': '核銷失敗',
        'refunded': '已退還',
        'refund-error': '退還失敗'
      }[ s ] || '(n/a)';
    }
  }
}
</script>

<style scoped>
div:not(.v-dialog) > .theme--light.v-card {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>