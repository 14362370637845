<template>
  <v-container>
    <!-- <v-card v-for="c in campaigns" :key="c.id">
      <v-card-title>{{ c.name }}</v-card-title>
      <v-card-text>{{ c.description }}</v-card-text>
      <v-card-actions>
        <v-btn>編輯</v-btn>
        <v-btn>送出審核</v-btn>
      </v-card-actions>
    </v-card> -->

    <!-- <v-text-field dense filled rounded hide-details class="mb-3" clearable
      label="搜尋" prepend-inner-icon="mdi-magnify"
      v-model="searchKeyword">
    </v-text-field> -->

    <!-- List of campaigns -->

    <SSRTable ref="list-campaigns" :no-data-text="'沒有可管理的活動'" hide-headers
      :data-url="fetchCampaignsUrl" :data-key="'campaigns'" :cols="cols">

      <!-- <div slot="prototype" slot-scope="{ item }">
        {{ item['prototype'] | toPrototypeLiteral }}
      </div>
      <div slot="time" slot-scope="{ item }">
        {{ item.start_time | toShortDateString }}-<br>
        {{ item.end_time | toShortDateString }}
      </div>
      <div slot="actions" slot-scope="{ item }">
        <v-btn x-small icon color="blue-grey lighten-3" class="ma-2"
          @click="goToCampaignScanner(item)">
          <v-icon>mdi-qrcode-scan</v-icon>
        </v-btn>
        <v-btn x-small outlined fab color="blue-grey lighten-3" class="ma-2">
          <v-icon>mdi-chart-pie</v-icon>
        </v-btn>
      </div> -->

      <div slot="props" slot-scope="{ item }">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ item.campaignName }}</v-list-item-title>
            <v-list-item-subtitle>{{ item['prototype'] | toPrototypeLiteral }}</v-list-item-subtitle>
            <span>{{ item.campaignStartTime | toShortDateString }} - {{ item.campaignEndTime | toShortDateString }}</span>
          </v-list-item-content>
        </v-list-item>
        
      </div>

      <div slot="actions" slot-scope="{ item }">
        <div class="d-flex justify-end">
          <v-btn x-small icon color="blue-grey lighten-3" class="ma-2" v-if="isScannerEligible(item)"
            @click="goToCampaignScanner(item)">
            <v-icon>mdi-qrcode-scan</v-icon>
          </v-btn>
          <v-btn x-small icon color="blue-grey lighten-3" class="ma-2"
            @click="goToCampaignStats(item)">
            <v-icon>mdi-chart-pie</v-icon>
          </v-btn>
        </div>
        
      </div>

    </SSRTable>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable.vue';
// import DateRangePicker from '@/components/DateRangePicker.vue';

// import SSORequest from '@/sso-request';
// import axios from 'axios';
import moment from 'moment';

export default {
  components: {
    SSRTable,
    // DateRangePicker
  },

  mounted() {
    // this.fetchCampaigns();
  },

  methods: {

    // fetchCampaigns() {
    //   axios.get(`${process.env.VUE_APP_TY_CAMPAIGNS_HOST}/v1/merchants/${this.user.merchantId}/campaigns?from=20220301&to=20220630`)
    //   .then(response => {
    //     console.log(`[CAMPAIGN LIST] fetchCampaigns: got campaigns`, response.data);
    //     this.campaigns = response.data.campaigns;
    //   })
    //   .catch(err => {
    //     console.error(err);
    //   });
    // }

    goToCampaignStats(campaign) {
      this.$router.push('/admin/campaigns/' + campaign.campaignId + '?campaignName=' + campaign.campaignName);
    },

    goToCampaignScanner(campaign) {
      this.$router.push('/admin/scanner-checkin?campaignId=' + campaign.campaignId);
    }
  },

  data() {
    return {
      campaigns: [ ],
      cols: [
        // { text: '活動名稱', value: 'name', sortable: false },
        // { text: '類型', value: 'prototype', width: '80px', sortable: false },
        // { text: '時間', value: 'time', width: '80px' },
        // { text: '', value: 'actions' },
        { text: '', value: 'props' },
        { text: '', value: 'actions', width: '480px' }
      ],
      searchKeyword: '',

      isScannerEligible: campaign => {
        const nowTs = moment().unix();
        return [ 'check-in' ].includes(campaign.prototype)
          && campaign.campaignStartTime <= nowTs
          && campaign.campaignEndTime >= nowTs;
      }
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    fetchCampaignsUrl() {
      let memberId = this.$store.getters.user.memberId;
      // let fromTs = moment(this.orders.dates[0], 'YYYY-MM-DD').startOf('day').unix();
      // let toTs = moment(this.orders.dates[1], 'YYYY-MM-DD').endOf('day').unix();
      return `${process.env.VUE_APP_TY_CAMPAIGNS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/campaigns?from=20220101&to=20301231`//FIXME: Literally all-time.
        + (this.searchKeyword && this.searchKeyword.length? `&search=name:${this.searchKeyword}`: '')
        + `&memberId=${memberId}`;
    },
  },

  filters: {
    toShortDateString(ts) {
      return moment.unix(ts).format('YY/M/D');
    },
    toPrototypeLiteral(t) {
      return {
        'qna': '問答',
        'collectible': '集點',
        'minigame': '小遊戲',
        'stamp-collection': '消費集點',
        'check-in': '打卡'
      }[ t ] || '';
    }
  }
}
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: transparent;
}
</style>

