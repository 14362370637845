export default {

  tw: {
    'store-list': '特店列表',
    'store-info': '特店資訊',
    'store-edit': '特店編輯',
    'store-managers': '權限管理',
    'campaign-list': '活動列表',
    'campaign-stats': '活動成效',
    'notifications': '通知中心',
    'transactions': '特店交易列表',
    'withdrawals': '點數提領紀錄',
    'apply': '特約商店申請',
    'withdraw': '申請提領',
    'account': '會員中心',
    'qrcode-reader': '掃碼報到',
    'scanner-checkin': '報到掃碼',
    'scanner-stamps': '集點發放',
    'store-stamp-cards': '集點卡管理',
    'store-stamp-card-transactions': '集點交易記錄',
    'store-stamp-card-create': '新增集點卡',
    'store-stamp-card-info': '集點卡資訊',
    'store-stamp-card-edit': '設定集點卡',
    'store-tickets': '票券',
    'store-ticket-history': '票券核銷記錄'
  }
};