<template>
  <v-container>


    <!-- Campaign/Mission Selector -->
    <v-select filled rounded hide-details class="mb-2"
      prepend-inner-icon="mdi-calendar"
      v-model="selectedCampaignId" :items="campaignsAsOptions" @change="onSelectedCampaignChanged">
    </v-select>

    <!-- Search-by-phone-number input -->
    <v-text-field filled rounded hide-details class="mb-2" readonly
      placeholder="掃描會員碼或以電話號碼搜尋會員" clearable
      prepend-inner-icon="mdi-phone" v-model="phoneNumber">
    </v-text-field>

    <!-- QrCode Reader -->
    <QrcodeReader ref="qrcode-reader" :placeholder-text="'請先選擇主掃活動／任務'"
      @detect="onQrcodeDetected"></QrcodeReader>

    <!-- Numpad for entering phone number -->
    <Numpad class="mt-2"
      @enter="onNumpadEntered" @confirm="onNumpadConfirmed">
    </Numpad>

    <!-- Dialog - check-in confirmation -->
    <v-dialog v-model="dialog.checkIn.show">

      <v-card>
        <v-card-title class="d-block text-center">
          {{ dialog.checkIn.title }}
        </v-card-title>
        <v-card-text class="text-center d-flex flex-column">
          <CustomIcon :src="require('@/assets/icons/success.png')" :height="72" class="mx-auto"
            v-if="dialog.checkIn.type == 'success'">
          </CustomIcon>
          <CustomIcon :src="require('@/assets/icons/error.png')" :height="72" class="mx-auto"
            v-else-if="dialog.checkIn.type == 'error'">
          </CustomIcon>
          <CustomIcon :src="require('@/assets/icons/warning.png')" :height="72" class="mx-auto"
            v-else>
          </CustomIcon>
          <span class="mt-4 text-center" v-if="dialog.checkIn.type != 'error'">
            <span class="text-subtitle-1 font-weight-bold">{{ dialog.checkIn.member.name }} | </span>
            <span class="text-subtitle-1">{{ dialog.checkIn.member.phone }}</span>
          </span>
          
        </v-card-text>

        <v-card-actions>
          <v-btn block rounded color="primary"
            @click="closeCheckInConfirmDialog">
            確認
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-container>
</template>

<script>
import QrcodeReader from '@/components/QrcodeReader.vue';
import Numpad from '@/components/Numpad.vue';

import SSORequest from '@/sso-request';
// import axios from 'axios';

export default {
  components: {
    QrcodeReader,
    Numpad
  },

  mounted() {
    this.fetchCampaigns();
    if (this.$route.query.campaignId) {
      this.selectedCampaignId = this.$route.query.campaignId;
      this.$refs['qrcode-reader'].start();
    }
  },

  methods: {
    fetchCampaigns() {
      SSORequest.get(
        `${process.env.VUE_APP_TY_CAMPAIGNS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/campaigns?from=20220301&to=20300630`
        + `&memberId=${this.user.memberId}`
      )
      .then(response => {
        console.log(`[QRCODE-READER]<DEBUG> fetchCampaigns: got campaigns`, response.data);

        //TODO: Filter only one-time accessible campaigns.
        //NOTE: Like check-in campaigns.
        this.campaigns = response.data.campaigns.filter(c => {
          return [ 'check-in' ].includes(c['prototype']);
        });
      })
      .catch(err => {
        console.error(err);
      });
    },

    onSelectedCampaignChanged() {
      this.$refs['qrcode-reader'].start();
    },

    onNumpadEntered(n) {
      if (!this.phoneNumber)
        this.phoneNumber = '';//FIXME: Because click:clear will set this to null.
      if (n == 'backspace') {
        if (this.phoneNumber.length)
          this.phoneNumber = this.phoneNumber.substring(0, this.phoneNumber.length - 1);
      }
      else {
        this.phoneNumber += `${n}`;
      }
    },
    onNumpadConfirmed() {
      this.checkInByPhone();
    },

    onQrcodeDetected(raw) {
      console.warn(`[SCANNER-CHECKIN] onQrcodeDetected`, raw);

      // FIXME: Temporarily pause the camera scanning.
      this.$refs['qrcode-reader'].pause();

      // Obtain member ID for the given one-time code.
      console.warn(`[SCANNER-CHECKIN] onQrcodeDetected: will look for member data by code ${raw}...`);
      let member;
      SSORequest.get(
        `${process.env.VUE_APP_TY_MANAGER_HOST}/merchants/${this.user.merchantId}`
        + `/member-code/${raw}`
      )
      .then(response => {
        console.log(`[SCANNER-CHECKIN] onQrcodeDetected: got member data`, response.data);
        member = response.data;
        return this.submitCheckIn(member);
      })
      .catch(err => {
        console.error(`[SCANNER-CHECKIN] submitCode: failed`, err);
        if (err.response && err.response.data && err.response.data.code == 'MS103') {
          //NOTE: 報到失敗（最有可能是重複報到，應檢查error code）
          this.showCheckInConfirmDialog('warning', member, '已報到過');
        }
        else {
          this.showCheckInConfirmDialog('error', member, '查詢錯誤');
        }
      });
    },

    checkInByPhone() {
      if (!this.selectedCampaignId) {
        this.$notify({
          type: 'error', text: '請先選擇報到活動！'
        });
        return;
      }
      if (!this.phoneNumber.length) {
        this.$notify({
          type: 'error', text: '請輸入電話號碼或掃描會員碼！'
        });
        return;
      }
      if (!this.phoneNumber.match(new RegExp('\\d{10}', 'g'))) {//NOTE: 限制用numpad輸入，所以應該不會有這問題
        this.$notify({
          type: 'error', text: '電話號碼格式有誤！'
        });
        return;
      }

      SSORequest.post(
        `${process.env.VUE_APP_TY_IDENTITIES_HOST}/v1/merchants/${this.user.merchantId}`
        + `/members/search-by-phone`,
        { phone: this.phoneNumber }
      )
      .then(response => {
        // this.member = {
        //   id: response.data.memberId,
        //   name: response.data.name,
        //   phone: this.phoneNumber
        // };

        // 發起會員報到
        return this.submitCheckIn({
          id: response.data.memberId,
          name: response.data.name,
          phone: this.phoneNumber
        });
      })
      .then(member => {
        this.phoneNumber = '';
      })
      .catch(err => {
        this.$notify({
          type: 'error', text: `查無此會員！（${this.phoneNumber}）`
        });
      });
    },
    
    submitCheckIn(member) {
      console.log(`[SCANNER-CHECKIN] submitCheckIn: will check-in for member`, member);
      let campaign = this.campaigns.find(c => c.campaignId === this.selectedCampaignId);
      return SSORequest.post(
        `${process.env.VUE_APP_TY_CAMPAIGNS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/campaigns/${campaign.campaignId}/complete`,
        { memberId: member['id'] }
      )
      .then(response => {
        console.log(`[SCANNER-CHECKIN] submitCheckIn: check-in succeeded`, response.data);
        
        //NOTE: 會員完成報到！顯示成功提示dialog
        this.showCheckInConfirmDialog('success', member, '報到成功');
        return member;
      })
      .catch(err => {
        console.error(`[SCANNER-CHECKIN] submitCheckIn: check-in failed`, err.response);
        if (err.response && err.response.data && err.response.data.code == 'MS103') {
          //NOTE: 報到失敗（最有可能是重複報到，應檢查error code）
          this.showCheckInConfirmDialog('warning', member, '已報到過');
        }
        // else {
        //   this.showCheckInConfirmDialog('error', member, '查詢錯誤');
        // }
        else
          throw err;
      });
    },

    showCheckInConfirmDialog(type, member, title) {
      this.dialog.checkIn.type = type;
      this.dialog.checkIn.member = member;
      this.dialog.checkIn.title = title;
      this.dialog.checkIn.show = true;
    },
    closeCheckInConfirmDialog() {
      this.dialog.checkIn.show = false;
      //FIXME: Resume the camera.
      this.$refs['qrcode-reader'].start();
    }

  },

  data() {
    return {
      // Scopes
      // storeId: null,
      campaigns: [ ],
      selectedCampaignId: null,

      phoneNumber: '',

      dialog: {
        checkIn: {
          show: false,
          type: 'success',
          title: '報到成功',
          member: { name: '王小明', phone: '0987654321' }//member data
        }
      }
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    storesAsOptions() {
      let stores = this.$store.getters.stores;
      return stores.map(store => {
        return {
          text: store['name'],
          value: `${store['id']}` 
        };
      });
    },
    selectedStore() {
      if (!this.storeId)
        return { };
      return this.$store.getters.store(this.storeId);
    },

    campaignsAsOptions() {
      return this.campaigns.map(c => {
        let prototypeLiteral = {
          'check-in': '報到'
        }[ c['prototype'] ] || '';
        return {
          text: `${c['campaignName']}（${prototypeLiteral}）`,
          value: c['campaignId']
        };
      })
    }
  }
}
</script>

<style scoped>
/* .v-card {
  background: transparent !important;
} */

.centered-input >>> input {
  text-align: center
}
</style>