<template>
  <v-container>

    <!-- Optional product info pane -->
    <v-card outlined rounded="xl" class="mx-auto mb-3" v-if="product">
      <v-img :src="product.images[0]" height="120" v-if="product.images && product.images.length">
      </v-img>

      <v-row align="center" class="pt-2">

        <!-- General info -->
        <v-col cols="12">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-body-1 mb-2">
                {{ product.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ product.description }}
              </v-list-item-subtitle>
              <v-list-item-subtitle :class="{ 'red--text': product.useExpirationTs < nowTs }">
                使用期限 {{ product.useStartTs | toShortDateString }} - {{ product.useExpirationTs | toShortDateString }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card>

    <div class="my-2 d-flex justify-space-between align-center">
      <div class="flex-shrink-1">
        <v-btn large text class="text-subtitle-1" @click="onSearchOrdersClick">
          <v-icon left>mdi-magnify</v-icon>
          <div>
            <span v-if="search.length">{{ search }}</span>
            <span v-else>搜尋&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
        </v-btn>
        <v-btn icon class="mr-1" v-show="search.length"
          @click="clearSearchOrders">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="flex-shrink-1">
        <v-btn rounded depressed color="secondary" class="text-subtitle-1" @click="dialog.timeRange.show = true">
          {{ dates[0] | toDateString }} - {{ dates[1] | toDateString }}
        </v-btn>
      </div>
      <!-- <div class="mr-2">
        <v-btn icon @click="forceOrdersRefresh" :disabled="orders.refreshLoading">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div> -->
    </div>

    <!-- List of ticket transactions -->
    <SSRTable ref="list-ticket-transactions" :hideHeaders="true"
      :data-url="fetchTicketTransactionUrl" :data-key="'transactions'" :cols="cols"
      :default-per-page="5"
      @click-row="onTransactionClicked">

      <div slot="left" slot-scope="{ item }">
        <span class="text-subtitle-2">{{ item.name }}</span><br>
        <span class="grey--text">{{ item.memberName }} - {{ item.memberPhone }}</span>
      </div>

      <div slot="right" slot-scope="{ item }">
        <span class="text-subtitle-2">{{ item.transactionStatus | toTransactionStatusLiteral}}</span><br>
        <span class="grey--text">{{ item.transactionTs | toDateTimeString }}</span>
      </div>

    </SSRTable>

    <!-- Dialog - Order Search -->
    <v-dialog v-model="dialog.search.show">
      <v-card>
        <v-card-title>搜尋電話號碼</v-card-title>
        <v-card-text>
          <v-text-field dense filled rounded hide-details type="number"
            prepend-inner-icon="mdi-magnify" clearable
            v-model="search">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary"
            @click="fetchTicketHistory(); dialog.search.show = false;">
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog - Order Query Time Range picker -->
    <v-dialog v-model="dialog.timeRange.show">
      <v-card>
        <v-card-title>查詢時間區間</v-card-title>
        <v-card-text>
          <DateRangePicker v-model="dates" :shortcuts="dialog.timeRange.shortcuts"></DateRangePicker>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="fetchTicketHistory(); dialog.timeRange.show = false">
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable.vue';
import DateRangePicker from '@/components/DateRangePicker.vue';

import SSORequest from '@/sso-request';
import moment from 'moment';

export default {
  components: {
    SSRTable,
    DateRangePicker
  },
  props: {
    storeId: String
  },

  mounted() {
    if (this.$route.query.productId) {
      this.productId = this.$route.query.productId;
      this.fetchProductInfo();
    }
  },

  methods: {

    fetchProductInfo() {
      SSORequest.get(`${process.env.VUE_APP_TY_TICKETS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/stores/${this.storeId}/products/${this.productId}`)
      .then(response => {
        this.product = response.data;
      })
      .catch(err => {
        this.$notify({
          type: 'error',
          text: '無法取得票券產品資訊。'
        })
      });
    },
    fetchTicketHistory() {
      setTimeout(() => {
        this.$refs['list-ticket-transactions'].loadData()
        .then(data => {
          // this.orders.refreshLoading = false;
          // this.orders.data = data;
        });
      });
    },

    onTransactionClicked(order) {
      console.log(`[TICKET-HISTORY] onTransactionClicked`, order);
      // this.dialog.order.data = order;
      // this.dialog.order.show = true;
    },

    onSearchOrdersClick() {
      this.search = '';
      this.dialog.search.show = true;
    },
    clearSearchOrders() {
      this.search = '';
      this.fetchTicketHistory();
    },

  },

  data() {
    return {
      productId: null,
      product: null,

      cols: [
        { text: '交易', value: 'left' },
        { text: '時間', value: 'right', align: 'right' }
      ],

      // Dialogs
      dialog: {
        detail: {
          show: false,
        },
        search: {
          show: false
        },
        timeRange: {
          show: false,
          // dates: [
          //   moment().startOf('day').toDate(),
          //   moment().endOf('day').toDate()
          // ],
          shortcuts: [
            { text: '今天', dates: [ moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD') ] },
            { text: '昨天', dates: [ moment().add(-1, 'days').format('YYYY-MM-DD'), moment().add(-1, 'days').format('YYYY-MM-DD') ] },
            { text: '本週', dates: [ moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD') ] },
            {
              text: '上週', dates: [
                moment().add(-1, 'weeks').startOf('week').format('YYYY-MM-DD'),
                moment().add(-1, 'weeks').endOf('week').format('YYYY-MM-DD')
              ]
            },
          ]
        }
      }
    };
  },

  computed: {
    fetchTicketTransactionUrl() {
      if (!this.storeId) return null;
      let fromTs = moment(this.dates[0], 'YYYY-MM-DD').startOf('day').unix();
      let toTs = moment(this.dates[1], 'YYYY-MM-DD').endOf('day').unix();
      return `${process.env.VUE_APP_TY_TICKETS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/stores/${this.storeId}/transactions?foo=bar`
        + (this.$route.query.productId? `&productId=${this.$route.query.productId}`: '')
        + `&from=${fromTs}&to=${toTs}`
        + ((this.search && this.search.length)? `&search=${this.search}`: '');
    },
    
    user() {
      return this.$store.getters.user;
    },
    nowTs() {
      return moment().unix();
    }
  },

  filters: {
    toDateString(t) {
      return moment(t, 'YYYY-MM-DD').format('YYYY/M/D');
    },
    toDateTimeString(ts) {
      if (!ts)
        return '';
      return moment.unix(ts).format('YYYY/M/D h:mmA');
    },
    toShortDateString(ts) {
      if (!ts)
        return '';
      return moment.unix(ts).format('YYYY/M/D');
    },

    toTransactionStatusLiteral(s) {
      return {
        'redeemed': '已核銷',
        'redeem-error': '核銷失敗',
        'refunded': '已退還',
        'refund-error': '退還失敗'
      }[ s ] || '(n/a)';
    }
  }
}
</script>

<style scoped>
div:not(.v-dialog) > .theme--light.v-card {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>