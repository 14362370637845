<template>
  <div>
    <v-data-table :mobile-breakpoint="mobileBreakpoint" hide-default-footer :hide-default-header="hideHeaders" :no-data-text="noDataText"
      :headers="cols" :items="rows"
      :page.sync="page" :items-per-page="perPage" @page-count="pageCount = $event"
      :server-items-length="total"
      @update:page="onPageUpdated"
      :loading="loading"
      loading-text="Loading..."
      @click:row="onRowClicked">

      <!-- Customize header slots for searching/filtering feature -->
      <!-- <template v-for="col in cols" v-slot:[headerSlotNameMap[col.value]]="{ header }">
        <span :key="col.value">{{ header.text }}</span>
      </template> -->

      <template v-for="col in cols" v-slot:[itemSlotNameMap[col.value]]="{ item }">
        <slot :name="col.value" :item="item">
          {{ item[ col.value ] }}
        </slot>
      </template>

    </v-data-table>

    <v-pagination v-model="page" :length="pageCount" :total-visible="totalVisiblePages">
    </v-pagination>
  </div>
</template>

<script>

import SSORequest from '@/sso-request';
// import axios from 'axios';

export default {
  name: 'SSRTable',
  props: {
    cols: {
      type: Array,
      default: () => [ ]
    },
    dataUrl: {
      type: String
    },
    dataKey: String,
    data: {
      type: Array,
      default: () => [ ]
    },

    totalVisiblePages: {
      type: Number,
      default: 6
    },
    noDataText: {
      type: String,
      default: '沒有資料可顯示'
    },
    hideHeaders: {
      type: Boolean,
      default: false
    },
    defaultPerPage: {
      type: Number,
      default: 10
    },
    mobileBreakpoint: {
      type: Number,
      default: 200
    }
  },

  mounted() {
    this.loadData();
  },

  methods: {

    loadData(forceUrl) {
      
      this.loading = true;

      // Load only local data
      if (!this.dataUrl || !this.dataUrl.length) {
        if (this.data)
          this.fillTable(this.data);
        else
          this.rows = [ ];
        this.loading = false;
        return;
      }

      // Load data rows from server.
      let limit = this.perPage;
      let startIndex = this.perPage * (this.page - 1);
      let url = forceUrl || this.dataUrl;
      let queryStringStarter = this.dataUrl.indexOf('?') > 0? '&': '?';
      let getUrl = `${url}${queryStringStarter}limit=${limit}&offset=${startIndex}`;
      console.log(`[SSRTable] URL to fetch data is`, getUrl);
      return SSORequest.get(getUrl)
      .then(response => {
        this.fillTable(response.data, this.dataKey);
        return response.data;
      })
      .catch(err => {
        console.error(`[SSRTable] Failed to fetch data from ${getUrl}`, err);
        throw err;
      })
      .finally(() => {
        this.loading = false;
      });
    },

    fillTable(data, key) {
      
      if (key) {
        this.rows = data[ key ];
        this.total = data['total'];
      }
      else if (Array.isArray(data)) {
        const limit = this.perPage;
        const startIndex = this.perPage * (this.page - 1);
        this.rows = data.slice(startIndex, startIndex + limit);
        this.total = data.length;
        console.log(`[SSRTable] fillTable:`, data, key, startIndex, limit, this.total);
      }
      else
        throw new Error(`No valid list data given.`);
    },


    onRowClicked(item) {
      this.$emit('click-row', item);
    },
    onPageUpdated(page) {
      console.log(`[SSRTable] onPageUpdated`, page);
      this.loadData();
    }
  },

  data() {
    return {
      loading: false,
      rows: [ ],

      page: 1,
      pageCount: 1,
      perPage: this.defaultPerPage,
      total: 0
    }
  },

  computed: {
    headerSlotNameMap() {
      let ret = {};
      this.cols.forEach(col => {
        ret[col.value] = `header.${col.value}`;
      });
      return ret;
    },
    itemSlotNameMap() {
      let ret = { }
      this.cols.forEach(col => {
        ret[ col.value ] = `item.${col.value}`;
      });
      // console.log(ret);
      return ret;
    }
  }
}
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: transparent;
}

/* .lightgreen--text {
  color:#00E676;
} */
</style>