<template>
  <v-container>

    <!-- Campaign/Mission Selector -->
    <v-select filled rounded hide-details class="mb-2"
      placeholder="選擇集點卡"
      prepend-inner-icon="mdi-stamper"
      v-model="selectedStampCardId" :items="stampCardAsOptions" @change="onSelectedStampCardChanged">
    </v-select>
    <div>掃描會員碼搜尋會員</div>

    <!-- QrCode Reader -->
    <QrcodeReader ref="qrcode-reader" :placeholder-text="qrcodeReaderPlaceholder"
      @detect="onQrcodeDetected" style="background: rgba(0, 0, 0, 0.05)"></QrcodeReader>

    <!-- Num of stamps to give -->

    <div class="rounded-pill mt-2 pa-2" style="background: rgba(255,255,255,0.1)">
      <v-row>
        <v-col cols="6" class="d-flex justify-center align-center">
          <span class="text-h6" :class="{ 'text-focus': focusInputField === 'numOfStamps' }">
            發放集點數量
          </span>
        </v-col>
        <v-col cols="6" class="d-flex justify-center">
          <span class="text-h5">{{ numOfStamps }}</span>
          <!-- <input ref="input-num-of-stamps" type="number" class="centered-input" readonly
            v-model="numOfStamps" /> -->
        </v-col>
      </v-row>
    </div>
    <!-- <div class="d-flex justify-center">
      
    </div> -->

    <!-- Numpad for entering phone number -->
    <Numpad class="mt-2"
      :confirm-disabled="!numOfStamps"
      @enter="onNumpadEntered" @confirm="onNumpadConfirmed">
    </Numpad>

    <!-- A dummy spacing at the end of list FIXME: to avoid content overlapping -->
    <!-- <div style="height: 84px;">
    </div> -->

    <!-- Dialog - check-in confirmation -->
    <v-dialog v-model="dialog.give.show">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center d-flex flex-column">
          <div class="d-flex justify-center align-center">
            <span class="ml-4 text-h5 font-weight-bold" style="color:#22798C">點數 x {{ numOfStamps }}</span>
          </div>
          
          <span class="mt-4 text-center">
            <span class="text-subtitle-1">即將發放點數給</span><br>
            <span class="text-subtitle-1 font-weight-bold">{{ member.name }} | </span>
            <span class="text-subtitle-1">{{ member.phone }}</span>
          </span>
          
        </v-card-text>
        <v-card-actions class="d-flex">
          <v-btn rounded color="bgGray" class="flex-grow-1"
            :disabled="dialog.give.isProcessing"
            @click="closeGiveStampDialog">
            返回修改
          </v-btn>
          <v-btn rounded color="secondary" class="flex-grow-1" @click="giveStamps"
            :disabled="dialog.give.isProcessing" :loading="dialog.give.isProcessing">
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import QrcodeReader from '@/components/QrcodeReader.vue';
import Numpad from '@/components/Numpad.vue';

import SSORequest from '@/sso-request';
// import axios from 'axios';
import moment from 'moment';

export default {
  components: {
    QrcodeReader,
    Numpad
  },

  mounted() {
    this.fetchManageableStampCards();
    // if (this.$route.query.campaignId) {
    //   this.selectedCampaignId = this.$route.query.campaignId;
    //   this.$refs['qrcode-reader'].start();
    // }
    // this.dialog.give.show = true;
  },

  methods: {
    fetchManageableStampCards() {
      SSORequest.get(
        `${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/store-admins/${this.user.memberId}/stamp-cards`
      )
      .then(response => {
        console.log(`[SCANNER-STAMPS]<DEBUG> fetchManageableStampCards: got stamp cards`, response.data);
        
        //TODO: Filter only those stamp-cards active for stamp collection.
        const nowTs = moment().unix();
        this.stampCards = response.data.stampCards.filter(sc => {
          const storeRoleId = this.$store.getters.storeRoleId(sc.storeId);
          return [ 1, 2 ].includes(storeRoleId) && sc.collectStartTime <= nowTs && sc.collectEndTime >= nowTs;
        });

      })
      .catch(err => {
        console.error(`[SCANNER-STAMPS] fetchManageableStampCards: error`, err);
      });
    },

    onSelectedStampCardChanged() {
      this.$refs['qrcode-reader'].start();
    },

    onNumpadEntered(n) {
      // // For entering num-of-stamps
      // else {
        if (n == 'backspace') {
          this.numOfStamps = Math.floor(this.numOfStamps / 10);
        }
        else {
          if (this.numOfStamps == 0)
            this.numOfStamps = n;
          else if (this.numOfStamps < 1000)//NOTE: The allowed value is maximally 9999.
            this.numOfStamps = this.numOfStamps * 10 + n;
        }
      // }
      
    },
    onNumpadConfirmed() {
      if (!this.selectedStampCardId) {
        this.$notify({
          type: 'error', text: '請先選擇集點卡！'
        });
        return;
      }
      if (!Object.keys(this.member).length) {
        this.$notify({
          type: 'error', text: '請先掃描會員碼！'
        });
        return;
      }

      if (!this.numOfStamps) {
        this.$notify({
          type: 'error', text: '必須先輸入欲發給的集點數量！'
        });
        return;
      }
      
      // 發起確認給點
      this.dialog.give.show = true;
    },

    onQrcodeDetected(raw) {
      console.warn(`[SCANNER-STAMPS] onQrcodeDetected`, raw);

      // FIXME: Temporarily pause the camera scanning.
      this.$refs['qrcode-reader'].pause();

      // Obtain member ID for the given one-time code.
      console.warn(`[SCANNER-STAMPS] onQrcodeDetected: will look for member data by code ${raw}...`);
      let member;
      SSORequest.get(
        `${process.env.VUE_APP_TY_MANAGER_HOST}/merchants/${this.user.merchantId}`
        + `/member-code/${raw}`
      )
      .then(response => {
        console.log(`[SCANNER-STAMPS] onQrcodeDetected: got member data`, response.data);
        this.member = response.data;
        
        // 如果沒輸入集點數量，擋住
        if (!this.numOfStamps) {
          this.$notify({ type: 'error', text: '請輸入欲發給的集點數量！' });
          setTimeout(()=>{
            this.$refs['qrcode-reader'].start();//Resume the camera.
          }, 500);
          return;
        }
        this.dialog.give.show = true;
      })
      .catch(err => {
        console.error(`[SCANNER-STAMPS] submitCode: failed`, err);
        if (err.response?.data?.code === 'CP901') {
          this.$notify({ type: 'error', text: err.response?.data?.message });
        }
        this.$refs['qrcode-reader'].start();
      });
    },
    
    giveStamps() {
      const member = this.member;
      const numOfStamps = this.numOfStamps;
      if (!numOfStamps) {
        this.$notify({
          type: 'error', text: '必須先輸入欲發給的集點數量！'
        });
        this.$refs['qrcode-reader'].start();
        return;
      }

      console.log(`[SCANNER-STAMPS] giveStamps: will give ${numOfStamps}-stamps to member`, member);
      const sc = this.selectedStampCard;
      this.dialog.give.isProcessing = true;
      
      return SSORequest.post(
        `${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${this.user.merchantId}`
        + `/stores/${sc.storeId}/stamp-cards/${sc.stampCardId}/award-stamps`,
        { memberId: member['id'], amount: numOfStamps }
      )
      .then(response => {
        console.log(`[SCANNER-STAMPS] giveStamps: succeeded`, response.data);
        
        
        this.$notify({
          type: 'success', text: `已發給 ${member.name} ${numOfStamps}個集點。`
        });
      })
      .catch(err => {
        console.error(`[SCANNER-STAMPS] giveStamps: failed`, err.response);
        throw err;
      })
      .finally(() => {
        this.dialog.give.isProcessing = false;
        this.closeGiveStampDialog();
      });
    },

    closeGiveStampDialog() {
      this.$refs['qrcode-reader'].start();
      this.dialog.give.show = false;
    },

    // changeFocusInput(field) {
    //   console.log(`[SCANNER-STAMPS]<DBUEG> changeFocusInput`, field);
    //   this.focusInputField = field;
    // }

  },

  data() {
    return {
      // Scopes
      stampCards: [ ],
      selectedStampCardId: null,

      // Search
      member: { },

      // Num of stamps
      numOfStamps: 0,

      // Numpad focus
      focusInputField: 'numOfStamps',

      dialog: {
        give: {
          show: false,
          isProcessing: false,
          title: '已發放集點',
        }
      }
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    selectedStampCard() {console.log(this.selectedStampCardId);
      const ret = this.stampCards.find(sc => sc.stampCardId === this.selectedStampCardId);
      return ret || { };
    },

    storesAsOptions() {
      let stores = this.$store.getters.stores;
      return stores.map(store => {
        return {
          text: store['name'],
          value: `${store['id']}` 
        };
      });
    },
    selectedStore() {
      if (!this.storeId)
        return { };
      return this.$store.getters.store(this.storeId);
    },

    stampCardAsOptions() {
      return this.stampCards.map(sc => {
        return {
          text: `${sc.stampCardName} - ${sc.storeName}`,
          value: sc.stampCardId
        };
      })
    },
    qrcodeReaderPlaceholder() {
      return !Object.keys(this.selectedStampCard).length ? '請先選擇集點卡' : ''
    }
  }
}
</script>

<style scoped>
/* .v-card {
  background: transparent !important;
} */

input.centered-input {
  text-align: center;
  font-size: 1.5em;
  color: #333333;
  border: none;
  outline-width: 0;
}

.text-focus {
  color: #22798C;
}
</style>